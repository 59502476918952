body {
  height: 100vh;
  width: 100vw;
  background-color: black;
  margin: 0rem;
  overflow: hidden;
}
#image-track {
  display: flex;
  gap: 4vmin;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(0%, -50%);
  user-select: none;
}

#image-track > .image {
  width: 32vmin;
  height: 44.8vmin;
  object-fit: cover;
  object-position: 100% center;
}
.header {
  display: flex;
  text-align: center;
  height: 100px;
  background-color: black;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.home-button {
  margin: 35px;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: white;
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.about-button {
  margin: 35px;
  cursor: pointer;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: rgba(128, 128, 128, 0.705);
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.projects-button {
  margin: 35px;
  cursor: pointer;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: rgba(128, 128, 128, 0.705);
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.about-button:hover {
  color: white;
  transition: color 0.15s ease;
}
.projects-button:hover {
  color: white;
  transition: color 0.15s ease;
}
