.header {
  display: flex;
  text-align: center;
  height: 100px;
  background-color: black;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.projectspg-home-button {
  margin: 35px;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: rgba(128, 128, 128, 0.705);
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.projectspg-home-button:hover {
  color: white;
  transition: color 0.15s ease;
}
.projectspg-about-button {
  margin: 35px;
  cursor: pointer;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: rgba(128, 128, 128, 0.705);
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.projectspg-about-button:hover {
  color: white;
  transition: color 0.15s ease;
}
.projectspg-projects-button {
  margin: 35px;
  cursor: pointer;
  height: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: white;
  background-color: black;
  border-width: 0px;
  top: 50%;
}
.grid-container {
  display: grid;
  grid-template-columns: 600px 1fr 200px;
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}
.projects-text {
  margin-top: -30px;
  grid-column: 2;
  color: white;
  font-size: 30px;
  font-family: Calibri;
  user-select: none;
  margin-bottom: 100px;
}
.projects-header {
  font-size: 40px;
  margin-top: -10px;
  margin-bottom: -10px;
}
